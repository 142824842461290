
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import InfoIcon from '@material-ui/icons/Info';
import CallIcon from '@material-ui/icons/Call';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import MailIcon from '@material-ui/icons/Mail';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import CallIcon from '@material-ui/icons/Call';
import Settings from '../../Menu/GlobalConstant/Settings';
import LoaderComponent from '../../Menu/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import axios from 'axios';
import { HOST } from '../../Menu/config';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.role =window.sessionStorage.getItem('role')
        this.supportemail =window.sessionStorage.getItem('supportemail')
        this.supportmobile =window.sessionStorage.getItem('supportmobile')


        this.state = {
            flagError: false,
            netWorkError: false,
            inputname: "",
            erroertext: "",
            vehicleid: "",
            vehicle: "",
            flatno: "",
            fullname: "",
            mobile: "",
            comments: "",
            tc: false,
            bc: false,
            oem:window.sessionStorage.getItem('oem')==="true"?"true":"false",
        }

    }
     
    add = () => {
        let api = HOST + "/support/add"
        let body = {
            fullname: this.state.fullname,
            mobile: this.state.mobile,
            details: this.state.comments,
            email:""
        }
        let count = 1
        if (this.state.comments === "") {
            count--
        }
        if (this.state.comments) {
            if (Settings.Comments(this.state.comments).status) {
                count--
            } else {
                count++
                this.setState({ flagError: true, erroertext: Settings.Comments(this.state.comments).errmessgae, inputname: Settings.Comments(this.state.comments).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (Settings.PersonName(this.state.fullname).status) {
            if (Settings.mobilenumber(this.state.mobile).status) {

            } else {
                count++
                this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.mobile).errmessgae, inputname: Settings.mobilenumber(this.state.mobile).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        else {
            count++
            this.setState({ flagError: true, erroertext: Settings.PersonName(this.state.fullname).errmessgae, inputname: Settings.PersonName(this.state.fullname).inputname })
            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        }
        if (count === 0) {
            this.setState({ loader: true })
            axios.post(api, body, {
                headers: {
                    "authToken": this.token,
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.status === 201) {
                    this.setState({ loader: false,fullname:"",mobile:"",comments:"" })
                    this.showNotification("tc")
                }
            }).catch(err => {
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.data.statusid === 409) {
                        this.setState({ flagError: true, loader: false, erroertext: Settings.Vehicle(409).errmessgae, inputname: Settings.Vehicle(409).inputname })
                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                    }
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })
        }

    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: "Thanks so much reaching out! A team member will contact you soon." });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    render() {
        const { classes } = this.props;
        const smobile='tel:+91'+ this.supportmobile
        const  url = 'https://api.whatsapp.com/send?phone=' + this.supportmobile
        const mail='mailto:'+this.supportemail+'?subject=Gaido Request Call Back&body'

        return (
            <div >
                 {this.state.tc ? <Snackbar
                        place="tc"
                        color="success"
                        icon={ThumbUpIcon}
                        message={this.state.erroertext}
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    /> : ""}
                <GridContainer>
          <GridItem xs={12} sm={6} md={4}>

          </GridItem>
          <GridItem xs={12} sm={6} md={4}>

          </GridItem>
          <GridItem xs={12} sm={6} md={2}>
          </GridItem>
          <GridItem xs={12} sm={6} md={2}>
            <div style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: "center" }}>
              {/* <Button color="primary" onClick={() => {
                this.props.history.push('/vehicle/add')
              }}><AddBoxIcon /> Add Vehicle</Button> */}
              {this.role ==="IsAdmin" ? <Button color="primary"  onClick={() => {
                this.props.history.push('/support')
              }} >Support</Button> :""}
            </div>
          </GridItem>
        </GridContainer>
                <GridContainer>
               
                    <GridItem xs={12} sm={6} md={12}>
                        <Card>
                       
                            <CardHeader color="warning" >
                                <h4 className={classes.cardTitleWhite}> <LiveHelpIcon /> How can we help you </h4>
                                <p className={classes.cardCategoryWhite}>
                                    Find What you're looking for
                               </p>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <img alt="requestcallback" src={require('../../Menu/img/picturemessage_4zltlvoe.png')} style={{ width: "100%" }}></img>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>

                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <InfoIcon />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Request a call back</p>
                                                <h3 className={classes.cardTitle}>
                                                    Get In Touch With Us
                                                </h3>
                                            </CardHeader>
                                            <CardBody>
                                               {this.state.loader ? <LoaderComponent /> : ""}
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            labelText="* Name"
                                                            id="Name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                onChange: (event) => this.setState({ fullname: event.target.value }),
                                                                type: "text",
                                                                value:this.state.fullname,
                                                                error: (this.state.flagError && this.state.inputname === "PersonName") || (this.state.requirederror && this.state.fullname === "") ? true : false
                                                            }}
                                                        />
                                                        {this.state.requirederror && !this.state.fullname ? <SnackbarContent
                                                            icon={InfoIcon}
                                                            message={this.state.erroertext}
                                                            // close
                                                            color="danger"
                                                        /> : ""}
                                                        {this.state.flagError && this.state.inputname === "PersonName" ? <SnackbarContent
                                                            icon={InfoIcon}
                                                            message={this.state.erroertext}
                                                            // close
                                                            color="danger"
                                                        /> : ""}
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            labelText="* Mobile Number(10 digits)"
                                                            id="Mobile"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                onChange: (event) => {
                                                                    this.setState({ mobile: event.target.value })
                                                                    if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                                        this.setState({ flagError: false, erroertext: "" })
                                                                    } else {
                                                                        this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: Settings.mobilenumber(event.target.value).inputname })
                                                                    }
                                                                },
                                                                onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                                type: "number",
                                                                value:this.state.mobile,
                                                                error: (this.state.flagError && this.state.inputname === "Mobile" )|| (this.state.requirederror && this.state.mobile === "") ? true : false
                                                            }}
                                                        />
                                                        {this.state.requirederror && !this.state.mobile ? <SnackbarContent
                                                            icon={InfoIcon}
                                                            message={this.state.erroertext}
                                                            // close
                                                            color="danger"
                                                        /> : ""}
                                                        {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                                            icon={InfoIcon}
                                                            message={this.state.erroertext}
                                                            // close
                                                            color="danger"
                                                        /> : ""}
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            labelText="Comments"
                                                            id="Comments"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                onChange: (event) => this.setState({ comments: event.target.value }),
                                                                type: "text",
                                                                 value:this.state.comments,
                                                                error: (this.state.flagError && this.state.inputname === "Comments" )? true : false
                                                            }}
                                                        />
                                                        {this.state.flagError && this.state.inputname === "Comments" ? <SnackbarContent
                                                            icon={InfoIcon}
                                                            message={this.state.erroertext}
                                                            // close
                                                            color="danger"
                                                        /> : ""}
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        {this.state.fullname !== "" &&
                                                            this.state.mobile !== "" ?
                                                            <Button color="primary" onClick={this.add}>Send Request</Button> :
                                                            <Button onClick={() => {
                                                                if (Settings.requireerrors([this.state.mobile, this.state.fullname]).status === true) {
                                                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.mobile, this.state.fullname]).errmessgae })
                                                                    setTimeout(() => { this.setState({ requirederror: false }) }, 2000);
                                                                }
                                                            }}>Send Request</Button>
                                                        }
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>

                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <div style={{ cursor: "pointer" }}>
                                                {this.state.oem==="true"?<a href={smobile} style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                                        <CardIcon color="success">
                                                            <CallIcon />
                                                        </CardIcon>
                                                    </a> :  <a href="tel:+91 7373735668 " style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                                        <CardIcon color="success">
                                                            <CallIcon />
                                                        </CardIcon>
                                                    </a>  } 
                                                  
                                                </div>
                                                <p className={classes.cardCategory}> Give us a call</p>
                                                <h4 className={classes.cardTitle}>
                                                    {this.state.oem==="true"? this.supportmobile:"+917373735668"}
                                                </h4>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <p> <ContactSupportIcon/>In case of any queries regarding our  services</p>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Card>
                                            <CardHeader color="info" stats icon >
                                                <div style={{ cursor: "pointer" }}>
                                                {this.state.oem==="true"?<a href={mail} style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                                        <CardIcon color="info">
                                                            <MailIcon />
                                                        </CardIcon>
                                                    </a>:<a href="mailto:info@gaido.in?subject=Gaido Request Call Back&body" style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                                        <CardIcon color="info">
                                                            <MailIcon />
                                                        </CardIcon>
                                                    </a>}
                                                    
                                                </div>
                                                <p className={classes.cardCategory}>Write to us</p>
                                                <h4 className={classes.cardTitle}>
                                                    
                                                    {this.state.oem==="true"?this.supportemail:"info@gaido.in"}

                                                </h4>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <p><AlternateEmailIcon /> We're always here to help you.<br/><br/></p>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <div style={{ cursor: "pointer" }}>
                                                    {this.state.oem==="true"?<a href={url} style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                                        <CardIcon color="success">
                                                            <WhatsAppIcon />
                                                        </CardIcon>
                                                    </a>:<a href="https://api.whatsapp.com/send?phone=917373735668" style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                                        <CardIcon color="success">
                                                            <WhatsAppIcon />
                                                        </CardIcon>
                                                    </a>


                                                    }
                                                    
                                                </div>
                                                <p className={classes.cardCategory}>Chat with us</p>
                                                { 
                                                this.state.oem==="true"?<a href={url} style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                                   <Button color="primary">Start chat</Button>
                                                  </a>:<a href="https://api.whatsapp.com/send?phone=917373735668" style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                                      <Button color="primary">Start chat</Button>
                                                  </a>}
                                            </CardHeader>
                                            <CardFooter style={{height:65,alignItems:"flex-start"}} stats>
                                                <p><RecentActorsIcon/> We define modern customer services.</p>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter stats>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default  withRouter((withStyles(styles)(Dashboard)));
