//empty error name 
const EmptyError = "Please fill out this field."
const NumberShouldNotZero = "Mobile not start with '0'"
const CommenstLengthErrors = "Comments should be maximum 25 characters."
//validation regrex 
const Aplhabets = /^[A-Za-z]+$/;
const hexadecimal=/[0-9A-Fa-f]{24}/;
const emailPattern = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phonenoPattern = /^\d{10}$/
const AlphanumericPattern = /^[a-z0-9]+$/i;
const Timevalidation =/^([0]\d|[1][0-2]):([0-5]\d)\s?(?:AM|PM)$/

// Company
const WebsitePattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
const CompanyURLPattern = new RegExp(/(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/)
const Companyminmax = "Company name should be minimum 3 and maximum 20 characters.";
const CompanyName = "Special characters are not allowed.";
const TagMaxLength="Tag ID Should be of 24 characters.";
const tagID="Tag ID Allows only between (0-9) digits and (A-F) characters.";
const CompanyUrl = "Enter a valid Rating URL.";
const CompnayExisted = "name already existed."
const WebsiteUrl = "Enter a valid website URL.";
const DynamicUrl = "Enter a valid"
const phonenoPatternMsg = "Mobile Number must be exactly 10 Digits.";
//Events 
const Eventminmax = "Event name should be minimum 3 and maximum 20 characters.";
// Users
const UsersminmaxFirstname = "First name should be minimum 3 and maximum 8 characters.";
const UsersFirstalpha = "First name only characters are allowed.";
const UsersminmaxLastname = "Last name should be minimum 3 and maximum 8 characters.";
const Userslastalpha = "Last name only characters are allowed.";
const PersonNameminamx = " Person Name should be minimum 3 and maximum 20 Characters.";
const PersonCharacters = "Only Alphabets are allowed."
//Login
const email_Login = "Enter a valid email address.";
const email_Login_Invalid = "Invalid logins.";
// Vehicle
const TokenStartError = "Token Number should not start with '0'."
const TokenLengthError = "Token Number should be minimum 3 and maximum 6 digits."
const VehicleLengthError = "Vehicle No. should be minimum 5 and maximum 10 Characters."
const TrackVehicleLengthError = "Vehicle No. should be minimum 2 and maximum 10 Characters."
const FlatNoLegthError = "EMP ID should be minimum 1 and maximum 10 Characters."
const dynamicaplahapatternError ="Special Characters are not allowed for "
//Forgot password
//  const validVerificationCode_Forgot = "Enter valid verification code";
//  const Email_Not_found = "Email not found";
//Bulk Upload 
const HedaerColoumError = "Required header columns should be"
const EmptyColomns = "Empty column's are not allowed."
//password
const passwordError = "Password length should be minimum 3 and maximum 20 "
const PasswordChange = "Password changed successfully."
//netwrok or Servererror or invalid body data 
const NetworkError = "Please check your internet connection and try again"
const ServerError = "Server Error! Please inform Gaido customer service."
const APIInvalidBodyError = "Invalid data try another "
const UnauthorizedError = "Invalid Username or Password "
const TimeError ="Please enter intime field format should be : time (12 hrs), Ex : 10:30 AM, 12:29 PM, etc."
const Statusformat  ="Please enter status format : checkedin (or) expected"
//success status 
const ProfileSuccess = "Profile updated successfully"
const Successadd = "added successfully"
const upadateSuccess = "updated successfully"
const deletesucces = "removed successfully"
const ImageUploadSizeError ="Uploaded image size too larze. The Max. image size 5MB."
const IMageURL =/https?:\/\/.*?\.(?:png|jpg|jpeg|gif)/ig
export default {tagID,hexadecimal,TagMaxLength,TrackVehicleLengthError,dynamicaplahapatternError,ImageUploadSizeError,IMageURL,Statusformat,  TimeError, Timevalidation,EmptyColomns, HedaerColoumError, PasswordChange, PersonCharacters, PersonNameminamx, FlatNoLegthError, CommenstLengthErrors, deletesucces, upadateSuccess, Successadd, CompnayExisted, UsersFirstalpha, UsersminmaxLastname, Userslastalpha, ProfileSuccess, Aplhabets, UnauthorizedError, EmptyError, NumberShouldNotZero, VehicleLengthError, TokenLengthError, TokenStartError, Eventminmax, DynamicUrl, WebsitePattern, AlphanumericPattern, APIInvalidBodyError, NetworkError, ServerError, UsersminmaxFirstname, passwordError, emailPattern, phonenoPattern, Companyminmax, CompanyUrl, CompanyURLPattern, phonenoPatternMsg, WebsiteUrl, CompanyName, email_Login, email_Login_Invalid }