import React, { Component } from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import axios from "axios";
import { HOST } from "../../Menu/config";
import LoaderComponent from "../../Menu/GlobalConstant/LoaderComponent";
import InfoIcon from "@material-ui/icons/Info";
import Settings from "../../Menu/GlobalConstant/Settings";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { Edit } from "@material-ui/icons";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },

  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
class View extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.state = {
      flagError: false,
      netWorkError: false,
      erroertext: "",
      vehicleid: "",
      vehicle: "",
      flatno: "",
      fullname: "",
      mobile: "",
      comments: "",
      data: this.props.location.state.propdata,
    };
  }
  componentDidMount() {
    this.setState({ loader: true });
    let api = HOST + "/tag/get/" + this.props.location.state.propdata.tagid;
    axios
      .get(api, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          vehicleid: response.data.vehicleid,
          vehicle: response.data.vehicle,
          flatno: response.data.flatno,
          fullname: response.data.fullname,
          mobile: response.data.mobile,
          comments: response.data.comments,
        });
        this.setState({ loader: false });
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          this.setState({
            netWorkError: true,
            loader: false,
            erroertext: Settings.netWorkError().errmessgae,
          });
          setTimeout(() => {
            this.setState({ netWorkError: false });
          }, 3000);
        }
        if (err.response) {
          if (err.response.status === 502) {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.Server().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}></GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  {Settings.titleCase("Vehicle record")}
                </h4>
                <p className={classes.cardCategoryWhite}>
                  {Settings.capitalizeFLetter(
                    "Details pertaining to the vehicle"
                  )}
                </p>
              </CardHeader>
              <CardBody>
                {this.state.netWorkError ? (
                  <SnackbarContent
                    icon={InfoIcon}
                    message={this.state.erroertext}
                    // close
                    color="danger"
                  />
                ) : (
                  ""
                )}
                {this.state.loader ? <LoaderComponent /> : ""}
                <GridContainer>
                  <GridItem style={{ marginTop: 20 }} xs={12} sm={12} md={6}>
                    <h6
                      className={classes.cardCategory}
                      style={{ borderBottom: "0.8px ridge", fontWeight: "500" }}
                    >
                      Tag ID{" "}
                    </h6>
                    <h4 style={{ marginTop: 22, fontSize: 18 }}>
                      {this.state.data.tag}
                    </h4>
                  </GridItem>
                  <GridItem style={{ marginTop: 20 }} xs={12} sm={12} md={6}>
                    <h6
                      className={classes.cardCategory}
                      style={{ borderBottom: "0.8px ridge", fontWeight: "500" }}
                    >
                      Emp ID
                    </h6>
                    <h4 style={{ marginTop: 22, fontSize: 18 }}>
                      {this.state.data.flatno !== ""
                        ? this.state.data.flatno
                        : ""}
                    </h4>
                  </GridItem>
                  <GridItem style={{ marginTop: 20 }} xs={12} sm={12} md={6}>
                    <h6
                      className={classes.cardCategory}
                      style={{ borderBottom: "0.8px ridge", fontWeight: "500" }}
                    >
                      Vehicle Number{" "}
                    </h6>
                    <h4 style={{ marginTop: 22, fontSize: 18 }}>
                      {this.state.data.vehicle !== ""
                        ? this.state.data.vehicle
                        : ""}
                    </h4>
                  </GridItem>
                  <GridItem style={{ marginTop: 20 }} xs={12} sm={12} md={6}>
                    <h6
                      className={classes.cardCategory}
                      style={{ borderBottom: "0.8px ridge", fontWeight: "500" }}
                    >
                      Full Name{" "}
                    </h6>
                    <h4 style={{ marginTop: 22, fontSize: 18 }}>
                      {this.state.data.fullname !== ""
                        ? this.state.data.fullname
                        : ""}
                    </h4>
                  </GridItem>{" "}
                  <GridItem style={{ marginTop: 20 }} xs={12} sm={12} md={6}>
                    <h6
                      className={classes.cardCategory}
                      style={{ borderBottom: "0.8px ridge", fontWeight: "500" }}
                    >
                      Mobile{" "}
                    </h6>
                    <h4 style={{ marginTop: 22, fontSize: 18 }}>
                      {this.state.data.mobile !== ""
                        ? this.state.data.mobile
                        : ""}
                    </h4>
                  </GridItem>
                  <GridItem style={{ marginTop: 20 }} xs={12} sm={12} md={6}>
                    <h6
                      className={classes.cardCategory}
                      style={{ borderBottom: "0.8px ridge", fontWeight: "500" }}
                    >
                      Comments{" "}
                    </h6>
                    <h4 style={{ marginTop: 22, fontSize: 18 }}>
                      {this.state.data.comments !== ""
                        ? this.state.data.comments
                        : ""}
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/tags/edit",
                          state: {
                            propdata: this.props.location.state.propdata,
                          },
                        });
                      }}
                    >
                      Edit
                      <span
                        style={{ display: "inline-block", width: "21px" }}
                      ></span>
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/tags");
                      }}
                    >
                      <KeyboardBackspaceIcon />
                      Back
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}></GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(View));
