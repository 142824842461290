import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "../../Grids/DashboardTable";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import React, { Component } from 'react';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import { Icon } from '@iconify/react';
import cctvIcon from '@iconify/icons-mdi/cctv';
import LoaderComponent from '../../Menu/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import Settings from '../../Menu/GlobalConstant/Settings';
import { HOST } from '../../Menu/config';
import axios from 'axios';
import { set, ref } from "firebase/database";
import { db } from "../../firebaseConfig";


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem('token');
    this.mainPanel = React.createRef();

    this.count = 1;
    this.state = {
      mobileOpen: false,
      vehicles: [],
      invehiclescount: 0,
      outvehiclescount: 0,
      umatchcount: 0,
      latestvehicle: null,
      modalIsOpen3: false,
      imageviewindex: 0,
      empty: false,
      postperpage: 10,   //state assign
      currentpost: 1,
      indexperpage: 0,
      newPage: 0,
      newPage1: 10,
      loader: false,
      loader1: false,
      netWorkError: false,
      direction: "",
      eventtime: "",
      eventdate: "",
      CurrentDate: new Date(),
      imageCheck: false,
      parkingOpen: false,
      changesOn: "false",
      online: window.navigator.onLine,
      countdata: [],
      name: window.localStorage.getItem('company'),
      uuid: "",
      changedcount: "",

    }
  }

  handleImageClick = image => {
    this.setState({ image });
  };
  handleColorClick = color => {
    this.setState({ color });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute = () => {
    return window.location.pathname !== "/";
  };

  componentDidMount() {
    window.addEventListener('offline', this.handleNetworkChange);
    window.addEventListener('online', this.handleNetworkChange);
    // setTimeout(function () {
    //   window.location.reload(1);
    // }, 20 * 5000);
  }
  


  getlatestvehcileDetails = (eventdate, eventtime, outvehiclescount, umatchcount, latestvehicle, direction, fullimagepath, invehiclescount, loadersatate) => {
    this.setState({
      loader: true,
      eventdate: eventdate,
      eventtime: eventtime,
      latestvehicle: latestvehicle,
      direction: direction,
      fullimagepath: fullimagepath,
      invehiclescount: invehiclescount,
      outvehiclescount: outvehiclescount,
      umatchcount: umatchcount,
    })


  }
  customIsNaN(x) { return (typeof x === 'number' && x !== 0 && !x); }
  ReturunedImageFun = () => {

    // return !!pattern.test(str);

    // if (Alert.IMageURL.test(this.state.fullimagepath)) {
    //   return <img src={this.state.fullimagepath} alt="vehicle" style={{ width: "100%", }} />
    // } else {
    //   return <img src={require('../../img/noimage.png')} alt="vehicle" style={{ width: "100%", }} />
    // }
  }
  handleNetworkChange = () => {
    this.setState({ online: window.navigator.onLine });

  }
  updateCount = () => {
    let api = HOST + "/count/add"
    let pcount = ""
    if (this.state.changesOn === "true") {
      pcount = this.state.parkedcount
      this.setState({ changedcount: pcount })
      // window.localStorage.setItem('count', pcount)

    } else {

      pcount = (-this.state.parkedcount)
      this.setState({ changedcount: pcount })
      // window.localStorage.setItem('count', pcount)


    }
    let body =
    {
      parkedcount: pcount
    }
    if (this.state.online) {

      axios.post(api, body, {
        headers: {
          "authToken": this.token,
          'Accept': 'application/json',
          // 'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.statusText === "OK") {
          this.setState({ loader: false })
          this.setState({
            parkingOpen: false
          })

          window.location.reload()


        }
      }).catch(err => {
        if (err.message === "Network Error") {
          this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
          setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
        }

        if (err.response.status === 502) {
          this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
          setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
        }

      })
    }
    else {
      this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
      setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
    }
  }

  handleRadioChange = (event) => {

    this.setState({
      changesOn: event.target.value,


    })

  };

  render() {

    const { classes } = this.props;

    return (

      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            {this.state.netWorkError ? <SnackbarContent
              icon={InfoIcon}
              message={this.state.erroertext}
              // close
              color="danger"
            /> : ""}
          </GridItem>
        </GridContainer>
        <GridContainer>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <Icon icon={cctvIcon} flip="horizontal" />
                    </CardIcon>
                    {this.state.loader1 ? <LoaderComponent /> : ""}
                    <p className={classes.cardCategory}>{Settings.titleCase("In")}</p>
                    <h3 className={classes.cardTitle}>
                      {this.state.invehiclescount}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      {this.customIsNaN(Math.ceil(((this.state.invehiclescount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100)) === true ? "0 % " + Settings.capitalizeFLetter("of Total Vehicle Activity Today") : Math.ceil(((this.state.invehiclescount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100) + "% " + Settings.capitalizeFLetter("of Total Vehicle Activity Today")}
                      {/* { } */}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      < LocalTaxiIcon />
                    </CardIcon>
                    {this.state.loader1 ? <LoaderComponent /> : ""}
                    <p className={classes.cardCategory}>{Settings.titleCase("out")}</p>
                    <h3 className={classes.cardTitle}>{this.state.outvehiclescount}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      {this.customIsNaN(Math.ceil(((this.state.outvehiclescount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100)) === true ? "0 %  " + Settings.capitalizeFLetter("of Total Vehicle Activity Today") : Math.ceil(((this.state.outvehiclescount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100) + "% " + Settings.capitalizeFLetter("of Total Vehicle Activity Today")}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>


              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>

          </GridItem>
          <GridItem xs={12} sm={6} md={3}>

          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Today's activity")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {Settings.capitalizeFLetter("Tags activity on")} {moment(this.state.CurrentDate).format("DD-MM-YYYY")}
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="success"
                  tableHead={[
                    {
                      name: "S.No",
                      prop: "s.no"
                    },
                    {
                      name: "Match",
                      prop: "db_match"
                    },                   
                    {
                      name: "Tag ID",
                      prop: "tagid"
                    },
                    {
                      name: "Vehicle Number",
                      prop: "vehiclenumber"
                    },
                    {
                      name: "Direction",
                      prop: "direction"
                    },
                    // {
                    //   name: "Date",
                    //   prop: "eventdate"
                    // },
                    {
                      name: "Time",
                      prop: "eventtime"
                    },
                    // {
                    //   name: "Category",
                    //   prop: "vehicle_category"
                    // },
                   
                    {
                      name:"View", 
                      prop: "action"
                    }

                  ]}
                  vehicleLatestVehcileDetails={this.getlatestvehcileDetails}
                  tableData={this.state.vehicles}
                  routename="rfid"
                />
              </CardBody>
            </Card>

          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          </GridItem>
        </GridContainer>

      </div>


    );
  }
}

export default withStyles(styles)(Dashboard);
