// import * as React from 'react';
// import { DataGrid } from '@material-ui/data-grid';

// const columns = [
//   { field: 'id', headerName: 'ID', width: 90 },
//   {
//     field: 'firstName',
//     headerName: 'First name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'lastName',
//     headerName: 'Last name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 110,
//     editable: true,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.getValue(params.id, 'firstName') || ''} ${
//         params.getValue(params.id, 'lastName') || ''
//       }`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

// export default function FindTag() {
//   return (
//     <div style={{ height: 400, width: '100%' }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         pageSize={5}
//         checkboxSelection
//         disableSelectionOnClick
//       />
//     </div>
//   );
// }

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "Grids/FindTags";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import React, { Component } from "react";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
// npm install --save-dev @iconify/react @iconify/icons-mdi
import { Icon } from "@iconify/react";
import fileExcel from "@iconify/icons-mdi/file-excel";
import Button from "components/CustomButtons/Button.js";
import AddBoxIcon from "@material-ui/icons/ArrowBack";
import moment from "moment";
import XLSX from "xlsx";
import "./App.css";
import Settings from "../../Menu/GlobalConstant/Settings";
import { Backup } from "@material-ui/icons";
import { HOST } from "Menu/config";
import axios from "axios";
import { Switch } from "@material-ui/core";
class FindTag extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");

    this.state = {
      addcompany: false,
      currentdate: new Date(),
      tagData: [],
      mainData: [],
      getTagData: false,
      accessData: false,
    };
  }

  // componentDidMount() {
  //   let api = HOST + '/' + "rfid" + '/tagsgetall'
  //   axios.get(api, {
  //     headers: {
  //       "authToken": this.token,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //   }).then(response => {
  //     if (response.data !== "") {
  //       var tags = []
  //       response.data.forEach(element => {

  //         tags.push({ "rfidid": element.rfidid, "tag": element.tag })
  //       });
  //       this.setState({ tagData: tags })
  //     } else {
  //       this.setState({ tagData: [] })
  //     }
  //   })

  //   if (this.props.history.location.pathname === '/tags/findtags') {

  //   }

  // }
  componentDidMount() {
    if (window.sessionStorage.getItem("rfidtagmap") === "false")
      this.setState({ getTagData: false });
    if (window.sessionStorage.getItem("rfidtagmap") === "true")
      this.setState({ getTagData: true });
    let api = HOST + "/" + "rfid" + "/rfidtagmap";
    axios
      .get(api, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({ getTagData: response.data[0].rfidtagmap });

      });
  }

  handleFile(event) {
    let reader = new FileReader();
    let rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        defval: "",
      });
      //  const newArray = data.map(({ EMPID, ...rest }) => rest)
      let ex = JSON.parse(JSON.stringify(data).replace(/\s(?=\w+":)/g, ""));
      let stringjson = JSON.parse(
        JSON.stringify(ex).replace(/\s(?=\w+":)/g, "")
      );
      let FinalStringJSon = JSON.parse(
        JSON.stringify(stringjson).replace(/"\s+|\s+"/g, '"')
      );

      var trimmed = JSON.stringify(FinalStringJSon, (key, value) => {
        if (typeof value === "string") {
          return value.trim();
        }
        return value;
      });
      let InsertData = JSON.parse(trimmed);
      var mainarray = [];
      // this.state.tagData.forEach((tagid, i) => {
      //   console.log(i);
      //   InsertData.forEach((vehicledata, k) => {
      //     // if (i < InsertData.length ) {
      //     //   var data = []
      //     //   data = Object.assign(tagid, vehicledata);
      //     //   mainarray.push(data)
      //     //   // this.state.tagData.splice(1,i)

      //     // } else {
      //       var data = []
      //       data = Object.assign(tagid, { flatno: "", vehicle: "", mobile: "", fullname: "", comments: "" })
      //       mainarray.push(data)

      //   //  }

      //   })
      // })
      const len = InsertData.length;
      for (var i = 0; i < this.state.tagData.length; i++) {
        let tagid = this.state.tagData[i];
        if (i < len) {
          for (var j = 0; j < InsertData.length; j++) {
            let vehicledata = InsertData[j];
            let data = Object.assign(tagid, {
              flatno: vehicledata.EMPID,
              vehicle: vehicledata.VEHICLENUMBER,
              mobile: vehicledata.MOBILENUMBER,
              fullname: vehicledata.PERSONNAME,
              comments: vehicledata.COMMENTS,
            });
            mainarray.push(data);
            InsertData.splice(j, 1);
            break;
          }
        } else {
          let data = [];
          data = Object.assign(tagid, {
            flatno: "",
            vehicle: "",
            mobile: "",
            fullname: "",
            comments: "",
          });
          mainarray.push(data);
        }
      }
      this.setState({ mainData: mainarray });
      // if (data.length === 0) {
      //   this.setState({ emptystate: true, showtable: false })
      //   setTimeout(() => { this.setState({ emptystate: false }) }, 6000);
      // } else {
      //   let finalarray = []
      //   InsertData.filter(obj => {
      //     return finalarray.push(Object.assign({ vehicle: String(obj.VEHICLENUMBER).replace(/\s+/g, ''), flatno: String(obj.EMPID).replace(/\s+/g, ''), tagid: String(obj.TAGID).replace(/\s+/g, ''), fullname: String(obj.PERSONNAME).replace(/\s+/g, ''), mobile: String(obj.MOBILENUMBER).replace(/\s+/g, ''), comments: String(obj.COMMENTS).replace(/\s(?=\s)/g, '') }))
      //   })
      //   this.setState({ emptystate: false, showtable: true, mainarray: finalarray })
      //   if (Settings.BulkuploadHedearCheck(data, this.state.xlcoloums).status) {
      //     if (Settings.BulkuploadDataCheck(InsertData, this.state.xlcoloums).status) {
      //       this.setState({ BulkUploadButton: true })
      //     } else {
      //       this.setState({
      //         errorindex: Settings.BulkuploadDataCheck(InsertData, this.state.xlcoloums).errorindex,
      //         flagError: true, loader: false, showtable: true, erroertext: Settings.BulkuploadDataCheck(InsertData, this.state.xlcoloums).errmessgae,
      //       })
      //       setTimeout(() => { this.setState({ flagError: false }) }, 6000);
      //     }
      //   } else {
      //     this.setState({ flagError: true, headerror: true, mainarray: [], showtable: true, loader: false, erroertext: Settings.BulkuploadHedearCheck(data, this.state.xlcoloums).errmessgae })
      //     setTimeout(() => { this.setState({ flagError: false }) }, 10000);
      //   }
      // }
    };
    if (rABS) reader.readAsBinaryString(event.target.files[0]);
    else reader.readAsArrayBuffer(event.target.files[0]);
  }
  handleChange = (event) => {
    // this.setState({
    //   getTagData: event.target.checked
    // })
    var access = event.target.checked;

    let api = HOST + "/rfid/updaterfidtagmap";
    let body = {
      rfidtagmap: event.target.checked,
    };

    // this.setState({ showtable: true })
    this.setState({ loader: true });
    axios
      .put(api, body, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          // 'Content-Type': 'application/json'
        },
      })
      .then((res) => {
        this.setState({
          getTagData: access,
        });

        window.sessionStorage.setItem("rfidtagmap", access);
      })
      .catch((err) => {
        this.setState({ showtable: true });
        if (err.message === "Network Error") {
          this.setState({
            netWorkError: true,
            loader: false,
            erroertext: Settings.netWorkError().errmessgae,
          });
          setTimeout(() => {
            this.setState({ netWorkError: false });
          }, 3000);
        }
        if (err.response) {
          if (err.response.data.statusid === 409) {
            this.setState({
              flagError: true,
              loader: false,
              erroertext: Settings.Vehicle(409).errmessgae,
              inputname: Settings.Vehicle(409).inputname,
            });
            setTimeout(() => {
              this.setState({ flagError: false });
            }, 3000);
          }
          if (err.response.status === 502) {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.Server().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
        }
      });

    // console.log(event.target.checked)
    // setState({ ...state, [event.target.name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={10}>
                <Switch
                  checked={this.state.getTagData}
                  onChange={this.handleChange}
                  label="Gilad Gray"
                  name="checked"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                Get Tag Data &nbsp;
              </GridItem>
              {/* <GridItem xs={12} sm={3} md={3} lg={2} >
                <div style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                  <Button color="primary" onClick={() => {
                    this.props.history.push('/vehicle/bulk')
                  }}><Icon icon={fileExcel} />Bulk upload</Button>
                </div>
              </GridItem>*/}
              <GridItem xs={12} sm={3} md={3} lg={2}>
                <div style={{ display: "inline" }}>
                  {/* {this.state.tagData.length !== 0 ? <>

                    <input id="file" hidden type="file" accept=".csv,.xls,.xlsx"
                      onChange={(event) => {
                        this.handleFile(event)
                        this.setState({ showtable: false, mainarray: [], BulkUploadButton: false })
                        event.target.value = null;
                      }} />
                    <Button color="primary" style={{ margin: "10px" }}
                      onClick={() => {
                        this.setState({ showtable: false, mainarray: [], BulkUploadButton: false })
                        document.getElementById("file").click()
                      }}><span style={{ display: "inline-block", width: "28px" }}></span><Backup />Upload File<span style={{ display: "inline-block", width: "28px" }}></span></Button></>
                    :
                    <Button color="secondary" style={{ margin: "10px" }} ><span style={{ display: "inline-block", width: "28px" }}></span><Backup />Upload File<span style={{ display: "inline-block", width: "28px" }}></span></Button>}
 */}

                  <Button
                    color="secondary"
                    onClick={() => {
                      this.props.history.push("/tags");
                    }}
                  >
                    <AddBoxIcon /> BACK
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}></GridItem>
          <GridItem xs={12} sm={12} md={4}></GridItem>
          <GridItem xs={12} sm={12} md={4}></GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  {Settings.titleCase("Add Vehicle Details to the tags")}
                </h4>
                {/* <p className={classes.cardCategoryWhite}>
                  {Settings.capitalizeFLetter("Vehicle details as on ")} {moment(this.state.currentdate).format("DD-MM-YYYY")}
                </p> */}
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="success"
                  tableHead={[
                    {
                      name: "",
                      prop: "checkbox",
                    },
                    {
                      name: "S.No",
                      prop: "s.no",
                    },
                    {
                      name: "Tag ID",
                      prop: "tag",
                    },
                    
                    {
                      name: "EMP ID.",
                      prop: "flatno",
                    },
                    {
                      name: "Vehicle Number",
                      prop: "vehicle",
                    },
                    {
                      name: "Mobile Number",
                      prop: "mobile",
                    },
                    {
                      name: "Person Name",
                      prop: "fullname",
                    },
                    {
                      name: "Time",
                      prop: "eventtime",
                    },
                    {
                      name: "Action",
                      prop: "action"
                    }
                  ]}
                  tableData={this.state.mainData}
                  routename="rfid"
                  dataAccess={this.state.getTagData}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}></GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(FindTag));
