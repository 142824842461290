import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Search from "@material-ui/icons/Search";
import headerstyles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
// core components
import CustomInput from "components/serachinput/Serachinput";
import Button from "components/CustomButtons/Button.js";
// npm install --save-dev @iconify/react @iconify/icons-mdi
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { Icon, } from '@iconify/react';
import microsoftExcel from '@iconify/icons-mdi/microsoft-excel';
import TablePagination from '@material-ui/core/TablePagination';
import { withRouter } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import Alert from '../../Menu/GlobalConstant/Alert'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Snackbar from "components/Snackbar/Snackbar.js";
const marignstyles = {
    root: {
        margin: 0,
        width: '100%',
    }
}
const useStyles = makeStyles(styles, headerstyles, marignstyles);
function CustomTable(props) {
    const classes = useStyles();
    const [TableMaindata, setData] = React.useState([])
    const [TableMaindata1, setData1] = React.useState([])
    const [filterText, setFilterText] = React.useState("");
    const [tc, setTC] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { tableData, tableHead, tableHeaderColor, routename, erroertext, flagError, errorindex } = props;     /// returning posts 
    const indexOfLastPost = page * rowsPerPage;
    useEffect(() => {
        setData(tableData)
        setData1(tableData)
    }, [tableData]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const export_table_to_csv = (html, filename) => {
        var csv = [];
        var cols = [];
        if (filename === "exportdata.csv") {
            for (var j = 0; j < tableHead.length; j++) {
                if (tableHead[j].name !== "action") {
                    cols.push(tableHead[j].name)
                }
            }
            csv.push(cols.join(","));
            TableMaindata.forEach((x, i) => {
                var row = []
                tableHead.forEach((y, k) => {
                    // if (tableHead[y].name !== "action") {
                    if (y.prop === "isactive" && x[y.prop]) {
                        row.push(x[y.prop])
                    } else {
                        if (y.prop === "s.no") {
                            row.push(i + 1)
                        } else {
                            row.push(x[y.prop])
                        }
                    }
                    // }
                })
                csv.push(row.join(","));
            })
            download_csv(csv.join("\n"), filename);
        }

    }
    const download_csv = (csv, filename) => {
        var csvFile;
        var downloadLink;
        csvFile = new Blob([csv], { type: "text/csv" });
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    const row = (x, i, headers) => (
        <>
            {headers.map((y, k) => {
                return (
                    <TableCell className={classes.tableCell} key={k} style={{ backgroundColor: errorindex.length !== 0 && errorindex.find(element => element === indexOfLastPost + (i + 1)) ? "red" : "#fff", color: errorindex.length !== 0 && errorindex.find(element => element === indexOfLastPost + (i + 1)) ? "#fff" : "#000" }}>
                        {x[y.prop]}
                    </TableCell>
                )
            }
            )}
        </>
    )
    return (
        <div className={classes.tableResponsive}>
            {tc ? <Snackbar
                place="tc"
                color="success"
                icon={ThumbUpIcon}
                message={String(routename).charAt(0).toUpperCase() + String(routename).slice(1) + "  " + Alert.deletesucces}
                open={tc}
                closeNotification={() => setTC(false)}
                close
            /> : ""}
            <GridContainer >
                <GridItem xs={12} sm={12} md={5} lg={4}>
                    <CustomInput formControlProps={{
                        className: classes.root
                    }}
                        inputProps={{
                            placeholder: "Search",
                            onChange: (e) => {
                                setFilterText(e.target.value)
                                const filteritems = TableMaindata.filter(x => {
                                    const values = tableHead.filter((y, k) => {
                                        if (e.target.type !== "number") {
                                            return String(x[y.prop]).toLocaleLowerCase().includes(String(e.target.value).toLocaleLowerCase())
                                        } else {
                                            return String(x[y.prop]).includes(String(e.target.value))
                                        }
                                    })
                                    if (values.length > 0) {
                                        return true
                                    }
                                    else {
                                        return false
                                    }
                                });
                                if (!e.target.value) {
                                    setData(TableMaindata1)
                                } else {
                                    setData(filteritems)
                                }
                            }
                        }} />
                    <Button color="primary" aria-label="edit" justIcon round onClick={() => {
                        const filteritems = TableMaindata.filter(x => {
                            const values = tableHead.filter((y, k) => {
                                return String(x[y.prop]).toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
                            })
                            if (values.length > 0) {
                                return true
                            }
                            else {
                                return false
                            }
                        });
                        setData(filteritems)
                    }}>
                        <Search />
                    </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={7} lg={6}>
                    {flagError ? <SnackbarContent
                        icon={InfoIcon}
                        message={erroertext}
                        // close
                        color="danger"
                    /> : ""}
                </GridItem>
                <GridItem xs={12} sm={12} md={2} lg={2}>
                    <Button color="primary" onClick={() => {
                        var html = document.querySelector("table").outerHTML;
                        export_table_to_csv(html, "exportdata.csv")
                    }
                    }>
                        <Icon icon={microsoftExcel} />
            Export Excel</Button>
                </GridItem>
            </GridContainer>
            <Table className={classes.table}>
                {tableHead !== undefined
                    ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow className={classes.tableHeadRow}>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                        >
                                            {prop.name}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                <TableBody>
                    {TableMaindata.length !== 0 && TableMaindata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prop, key) => {

                        return (
                            <TableRow key={key} className={classes.tableBodyRow} >
                                {row(prop, key, tableHead)}
                            </TableRow>
                        )
                    })}
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>

                        </GridItem>
                    </GridContainer>
                </TableBody>
                <caption style={{
                    position: "relative",
                    top: "-53px",
                    bottom: 0,
                    left: "-13px",
                    color: "#000"
                }}> Total No.of Records : {TableMaindata.length}</caption>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={TableMaindata.length !== undefined ? TableMaindata.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Table>
        </div>
    );

}

CustomTable.defaultProps = {
    tableHeaderColor: "gray"
};

CustomTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
export default withRouter(CustomTable)
