import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Search from "@material-ui/icons/Search";
import headerstyles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
// core components
import CustomInput from "components/serachinput/Serachinput";
import Button from "components/CustomButtons/Button.js";
// npm install --save-dev @iconify/react @iconify/icons-mdi
import { Icon, } from '@iconify/react';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import microsoftExcel from '@iconify/icons-mdi/microsoft-excel';
import TablePagination from '@material-ui/core/TablePagination';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { withRouter } from "react-router-dom";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import AWS from 'aws-sdk';


// import LoaderComponent from '../../Menu/GlobalConstant/LoaderComponent'
const marignstyles = {
    root: {
        margin: 0,
        width: '100%',
    }
}

const useStyles = makeStyles(styles, headerstyles, marignstyles);


function CustomTable(props) {
    const classes = useStyles();
    const [TableMaindata, setData] = React.useState([])
    const [TableMaindata1, setData1] = React.useState([])
    // const [loader, setLoader] = React.useState(false)
    const finalCount = React.useRef([])
    const [filterText, setFilterText] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { tableData, tableHead, tableHeaderColor, datefro, dateto, routename } = props;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [imagevalue, setImagevalue] = React.useState("")
    const [imageopen, setImageopen] = React.useState(false)

    // const indexOfLastPost = page * rowsPerPage;     
    useEffect(() => {
        setData(tableData)
        setData1(tableData)
    }, [tableData]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property)
    };
    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);
    const stableSort = (array, comparator) => {
        finalCount.current = array.map((el, index) => [el, index]);
        finalCount.current.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return finalCount.current.map((el) => el[0]);
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const descendingComparator = (a, b, orderBy) => {
        if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
            return -1;
        }
        if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
            return 1;
        }
        return 0;
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const export_table_to_csv = (html, filename) => {
        var csv = [];
        var cols = [];
        for (var j = 0; j < tableHead.length; j++) {
            cols.push(tableHead[j].name)
        }
        csv.push(cols.join(","));
        TableMaindata.forEach((x, i) => {
            var row = []
            tableHead.forEach((y, k) => {
                if (y.prop === "isactive" && x[y.prop]) {
                    row.push(x[y.prop])
                } else {
                    if (y.prop === "s.no") {
                        row.push(i + 1)
                    } else {
                        row.push(x[y.prop])
                    }
                }
            })
            csv.push(row.join(","));
        })
        download_csv(csv.join("\n"), filename);
    }
    const download_csv = (csv, filename) => {
        var csvFile;
        var downloadLink;
        csvFile = new Blob([csv], { type: "text/csv" });
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    const row = (x, i, headers) => (
        <>
            {headers.map((y, k) => {

                if (y.prop === "db_match" && x[y.prop]) {
                    return (
                        <TableCell className={classes.tableCell} key={k}>
                            <FiberManualRecordRoundedIcon style={{ color: "green" }} />
                        </TableCell>
                    )
                }
                else if (y.prop === "db_match" && !x[y.prop]) {
                    return (
                        <TableCell className={classes.tableCell} key={k}>
                            <FiberManualRecordRoundedIcon style={{ color: "red" }} />
                        </TableCell>
                    )
                }
                else if (y.prop === "fullimagepath") {
                    return (
                        <TableCell className={classes.tableCell} key={k}>
                            <>
                                <Tooltip title={"Vehicle Image"} placement="bottom">
                                    <VisibilityIcon style={{ color: "#1877f2", cursor: "pointer", marginLeft: "34px" }} onClick={() => {
                                        setImagevalue(x.fullimagepath)
                                        setImageopen(true)
                                    }} />
                                </Tooltip>
                            </>
                        </TableCell>
                    )
                }
                else {
                    return (
                        <TableCell className={classes.tableCell} key={k}>
                            {x[y.prop]}
                        </TableCell>
                    )
                }
            }
            )}
        </>
    )

    

   
    return (
        <div>
            <Dialog
                // fullScreen={fullScreen}
                open={imageopen}
                onClose={() => setImageopen(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" onClose={() => setImageopen(false)}>
                    {""}
                    <IconButton aria-label="close" style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }} onClick={() => setImageopen(false)}>
                        <CancelOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <img src={imagevalue} alt="License veihcle" style={{ width: "100%", }} onError={(e) => {
                                e.target.src = "https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/noimage.png"
                            }} />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>
            <div className={classes.tableResponsive}>

                <GridContainer >
                    <GridItem xs={12} sm={12} md={5} lg={5}>
                        <CustomInput formControlProps={{
                            className: classes.root
                        }}
                            inputProps={{
                                placeholder: "Search",
                                onChange: (e) => {
                                    setFilterText(e.target.value)
                                    const filteritems = TableMaindata.filter(x => {
                                        const values = tableHead.filter((y, k) => {
                                            if (e.target.type !== "number") {
                                                return String(x[y.prop]).toLocaleLowerCase().includes(String(e.target.value).toLocaleLowerCase())
                                            } else {
                                                return String(x[y.prop]).includes(String(e.target.value))
                                            }
                                        })
                                        if (values.length > 0) {
                                            return true
                                        }
                                        else {
                                            return false
                                        }
                                    });
                                    if (!e.target.value) {
                                        setData(TableMaindata1)
                                    } else {
                                        setData(filteritems)
                                    }
                                }
                            }} />
                        <Button color="primary" aria-label="edit" justIcon round onClick={() => {
                            const filteritems = TableMaindata.filter(x => {
                                const values = tableHead.filter((y, k) => {
                                    return String(x[y.prop]).toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
                                })
                                if (values.length > 0) {
                                    return true
                                }
                                else {
                                    return false
                                }
                            });
                            setData(filteritems)
                        }}>
                            <Search />
                        </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2} style={{ marginRight: "10px" }} >

                       
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2} style={{ marginRight: "10px" }} >
                        
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}>

                        <Button color="primary" onClick={() => {
                            var html = document.querySelector("table").outerHTML;
                                export_table_to_csv(html, "Vehicle Activity from " + datefro + "to " + dateto + ".csv")
                            
                        }
                        }>
                            <Icon icon={microsoftExcel} />
                            Export Excel</Button>
                    </GridItem>

                </GridContainer>
                <Table className={classes.table}>

                    {tableHead !== undefined
                        // && !headerror 
                        ? (
                            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                                <TableRow className={classes.tableHeadRow}>
                                    {tableHead.map((prop, key) => {
                                        return (
                                            <TableCell
                                                className={classes.tableCell + " " + classes.tableHeadCell}
                                                key={key}
                                            >
                                                <TableSortLabel
                                                    IconComponent={ArrowUpwardIcon}
                                                    active={orderBy === prop.prop}
                                                    hideSortIcon={prop.name !== "S.No" && prop.name !== "Vehicle Image" && prop.name !== "Action" && prop.name !== "Share Activity" && prop.name !== "Comments" ? false : true}
                                                    direction={orderBy === prop.prop ? order : 'asc'}
                                                    onClick={() => {
                                                        if (prop.name !== "S.No" && prop.name !== "Vehicle Image" && prop.name !== "Action" && prop.name !== "Share Activity" && prop.name !== "Comments") {
                                                            handleRequestSort(prop.prop)
                                                        }
                                                    }}
                                                >
                                                    {prop.name}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                        ) : null}
                    <TableBody>
                        {TableMaindata.length !== 0 && stableSort(filterText ? TableMaindata.filter(x => {
                            const values = tableHead.filter((y, k) => {
                                if (typeof filterText !== "number") {
                                    return String(x[y.prop]).toLocaleLowerCase().includes(String(filterText).toLocaleLowerCase())
                                } else {
                                    return String(x[y.prop]).includes(String(filterText))
                                }
                            })
                            if (values.length > 0) {
                                return true
                            }
                            else {
                                return false
                            }
                        }) : TableMaindata, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prop, key) => {

                            return (
                                <TableRow key={key} className={classes.tableBodyRow}>
                                    {row(prop, key, tableHead)}
                                </TableRow>
                            );
                        })}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            </GridItem>
                        </GridContainer>
                    </TableBody>
                    <caption style={{
                        position: "relative",
                        top: "-53px",
                        bottom: 0,
                        left: "-13px",
                        color: "#000"
                    }}> Total No.of Records : {TableMaindata.length !== undefined ? (filterText ? finalCount.current.length : TableMaindata.length) : 0}</caption>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        labelRowsPerPage={"Records Per Page"}
                        // component="div"
                        // labelDisplayedRows={({ from, to, count }) => `Showing ${ page} to ${(page * rowsPerPage) > TableMaindata.length ? TableMaindata.length : page * rowsPerPage } of ${TableMaindata.length} Results`}
                        count={filterText ? finalCount.current.length : TableMaindata.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />

                </Table>


            </div>
        </div>
    );

}

CustomTable.defaultProps = {
    tableHeaderColor: "gray"
};

CustomTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
export default withRouter(CustomTable)
