import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import userimge from '../../Menu/img/user.jpg'
import axios from 'axios';
import { HOST, } from '../../Menu/config';
import LoaderComponent from '../../Menu/GlobalConstant/LoaderComponent'
import Settings from '../../Menu/GlobalConstant/Settings';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Alert from '../../Menu/GlobalConstant/Alert';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class Profile extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            loader: false,
            FirstName: '',
            LastName: '',
            PreviousFirstName: "",
            PreviousLastName: "",
            Email: '',
            Mobile: '',
            Username: '',
            Company: "",
            editbutton: false,
            requirederror: false,
            flagError: false,
            netWorkError: false,
            success: false,
            selectedFile: null,
            imageURL: '',
            isOpen: false,
            dataURL: '',
            imagePreviewUrl: "",
            erroertext: "",
            inputname: "",
            tc: false,
            bc: false,
            imageuploadbutton: false,
            imageUplaodloader: false,
            online: window.navigator.onLine,
            Role: localStorage.getItem('role'),
            editform: false,
        }
    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.ProfileSuccess });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };

    componentDidMount() {
        this.getProfileDetails()
        this.getLatestImage()
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
    }

    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }
    getProfileDetails = () => {
        this.setState({ loader: true })
        let api = HOST + "/user/profile"
        axios.get(api, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.imageURL = res.data.imagepath;
            this.setState({
                FirstName: res.data.firstname,
                LastName: res.data.lastname,
                PreviousFirstName: res.data.firstname,
                PreviousLastName: res.data.lastname,
                Email: res.data.email,
                Username: res.data.username,
                Company: res.data.company,
                imagePreviewUrl: res.data.imagepath,
                dataURL: res.data.imagepath
                // imageURL:'https://d3mfc6ugur9erw.cloudfront.net/userimages/'+res.data.imagepath
            })
            this.setState({ editform: false })
            this.setState({ loader: false })
        }).catch(err => {
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })
    }
    getLatestImage = () => {
        this.setState({ imageUplaodloader: true })
        let api = HOST + "/user/profile"
        axios.get(api, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.imageURL = res.data.imagepath;
            this.setState({
                imagePreviewUrl: res.data.imagepath,
                dataURL: res.data.imagepath
                // imageURL:'https://d3mfc6ugur9erw.cloudfront.net/userimages/'+res.data.imagepath
            })
            this.setState({ imageUplaodloader: false, editform: false })
        }).catch(err => {
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, imageUplaodloader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, imageUplaodloader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })
    }
    update = () => {
        let count = 1
        let api = HOST + "/user/updateprofile"
        let body = {
            firstname: this.state.FirstName, lastname: this.state.LastName, email: this.state.Email,
            profilepic: this.state.dataURL
        }
        if (this.state.Email === "") {
            count--
        }
        else {
            if (Settings.Email(this.state.Email).status) {
                count--
            } else {
                count++
                this.setState({ flagError: true, erroertext: Settings.Email(this.state.Username).errmessgae, inputname: Settings.Email(this.state.FirstName).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (count === 0) {
            if (Settings.FirstName(this.state.FirstName).status) {
                if (Settings.LastName(this.state.LastName).status) {
                    this.setState({ loader: true })
                    if (this.state.online) {

                        axios.put(api, body, {
                            headers: {
                                "authToken": this.token,
                                'Accept': 'application/json',
                                // 'Content-Type': 'application/json'
                            }
                        }).then(res => {

                            this.getProfileDetails()
                            this.setState({ loader: false, })
                            this.showNotification("tc")
                        }).catch(err => {
                            if (err.message === "Network Error") {
                                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                            }
                            if (err.response) {
                                if (err.response.status === 502) {
                                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                                }
                            }
                        })
                    }
                    else {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                } else {
                    this.setState({ flagError: true, erroertext: Settings.LastName(this.state.LastName).errmessgae, inputname: Settings.LastName(this.state.LastName).inputname })
                    setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                }
            } else {
                this.setState({ flagError: true, erroertext: Settings.FirstName(this.state.FirstName).errmessgae, inputname: Settings.FirstName(this.state.FirstName).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
    }
    fileSelect = event => {
        // debugger
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            if (file.size > 5000000) {
                this.setState({ flagError4: true, erroertext: Alert.ImageUploadSizeError });
                setTimeout(() => { this.setState({ flagError4: false }) }, 4000)
            } else if (file.size < 1000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    var img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        var canvas = document.createElement("canvas");
                        var context = canvas.getContext("2d");
                        canvas.width = img.width;
                        canvas.height = img.height;
                        context.drawImage(img,
                            0,
                            0,
                            img.width,
                            img.height,
                            0,
                            0,
                            canvas.width,
                            canvas.height);
                        this.setState({
                            dataURL: canvas.toDataURL(),
                            imageuploadbutton: true
                        }
                        );
                    }
                };
            } else {
                reader.readAsDataURL(file);
                reader.onload = () => {

                    var img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        var canvas = document.createElement("canvas");
                        var context = canvas.getContext("2d");
                        canvas.width = img.width / 10;
                        canvas.height = img.height / 10;
                        context.drawImage(img,
                            0,
                            0,
                            img.width,
                            img.height,
                            0,
                            0,
                            canvas.width,
                            canvas.height);
                        this.setState({
                            dataURL: canvas.toDataURL()
                        });
                        this.setState({ selectedFile: file })
                    }
                };
            }
            reader.onloadend = () => {
                this.setState({
                    imagePreviewUrl: reader.result
                });

            }
        }
    }
    fileUpload = () => {
        this.setState({ imageuploadbutton: false, imageUplaodloader: true })
        const obj = {
            profilepic: this.state.dataURL
        };
        axios.put(HOST + '/user/updateprofileimage', obj,
            {
                headers: {
                    "authToken": this.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                this.getLatestImage()
                this.showNotification("tc")
            })
            .catch((err) => {
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, imageUplaodloader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, imageUplaodloader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            });

    }
    upload() {
        document.getElementById("imagefile").click()
    }
    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                {this.state.tc ? <Snackbar
                    place="tc"
                    color="success"
                    icon={ThumbUpIcon}
                    message={this.state.erroertext}
                    open={this.state.tc}
                    closeNotification={() => this.setState({ tc: false })}
                    close
                /> : ""}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>

                        {this.state.flagError4 ? <SnackbarContent
                            icon={InfoIcon}
                            message={this.state.erroertext}
                            // close
                            color="danger"
                        /> : ""}
                        <Card>
                            <CardHeader color="primary" >
                                <CardAvatar profile>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                        <img style={{
                                            border: "0px groove #FFFFFF",
                                            backgroundColor: "#FFFFFF",
                                            // height: "100px",
                                            // borderRadius: "50%",
                                            // width: "100px",
                                        }} src={this.state.imagePreviewUrl === "" ? userimge : this.state.dataURL} alt="..." />
                                    </a>
                                </CardAvatar>
                                {/* <div style={{ position: "absolute", right: 50, top: 32 }}>
                                    <Button style={{ background: "#FFFFFF" }} aria-label="edit" justIcon round onClick={this.upload}>
                                        <CameraAltIcon style={{ color: "#4caf50" }} />
                                    </Button>
                                </div> */}

                                <input type="file" hidden onChange={this.fileSelect} accept="image/*" id="imagefile" />
                            </CardHeader>
                            {this.state.editform ? <div style={{ padding: "1.9375rem 42px" }}><CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                {this.state.imageuploadbutton ? <Button color={"primary"} onClick={this.fileUpload}>Upload Image</Button> : ""}
                                {this.state.imageUplaodloader ? <LoaderComponent /> : ""}

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="* Username"
                                            id="username"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: this.state.Username,
                                                type: "text",
                                                disabled: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="* Role"
                                            id="Role"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: localStorage.getItem('role') === "IsManager" ? "Manager" : localStorage.getItem('role') === "IsAdmin" ? "Admin" : "User",
                                                type: "text",
                                                disabled: true,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="* First Name(min. 3 & max. 8 characters)"
                                            id="first-name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ FirstName: event.target.value })
                                                    if (Settings.FirstName(event.target.value).status || event.target.value ==="") {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.FirstName(event.target.value).errmessgae, inputname: Settings.FirstName(event.target.value).inputname })
                                                    }
                                                },
                                                type: "text",
                                                value: this.state.FirstName,
                                                error: (this.state.FirstName === "" && this.state.requirederror) || (this.state.flagError && this.state.inputname === "FirstName") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.FirstName ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "FirstName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="* Last Name(min. 3 & max. 8 characters)"
                                            id="last-name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ LastName: event.target.value })
                                                    if (Settings.LastName(event.target.value).status || event.target.value ==="") {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.LastName(event.target.value).errmessgae, inputname: Settings.LastName(event.target.value).inputname })
                                                    }


                                                },
                                                type: "text",
                                                value: this.state.LastName,
                                                error: (this.state.LastName === "" && this.state.requirederror) || (this.state.flagError && this.state.inputname === "LastName") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.LastName ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "LastName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Email(Ex. info@gaido.in)"
                                            id="Email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ Email: event.target.value })
                                                    if (Settings.Email(event.target.value).status || event.target.value ==="") {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.Email(event.target.value).errmessgae, inputname: Settings.Email(event.target.value).inputname })
                                                    }
                                                },
                                                type: "text",
                                                value: this.state.Email,
                                                error: this.state.flagError && this.state.inputname === "Email" ? true : false
                                            }}
                                        />
                                        {this.state.flagError && this.state.inputname === "Email" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                                <CardFooter>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={5}>
                                            {this.state.FirstName !== "" && this.state.LastName !== "" ? <Button color={"primary"} onClick={this.update}>Update Profile</Button> :
                                                <Button
                                                    onClick={() => {
                                                        if (Settings.requireerrors([this.state.FirstName, this.state.LastName]).status === true) {
                                                            this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.FirstName, this.state.LastName]).errmessgae })
                                                            setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                        }
                                                    }}
                                                >Update Profile</Button>
                                            }
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={1}>
                                            <Button color="white" onClick={() => {
                                                this.setState({ editform: false })
                                            }}><CancelPresentationIcon />Cancel</Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                        </GridItem>
                                    </GridContainer>
                                </CardFooter></div> : <CardBody profile style={{ padding: "1.9375rem 42px" }}>
                                {this.state.imageuploadbutton ? <Button color={"primary"} onClick={this.fileUpload}>Upload Image</Button> : ""}
                                {this.state.imageUplaodloader ? <LoaderComponent /> : ""}
                                <h4>User Name : {this.state.Username}</h4>
                                <h4>Role : {this.state.Role === "IsManager" ? "Manager":(this.state.Role === "IsTenant"?"Tenant":"User")}</h4>
                                <h4>Profile Name : {this.state.PreviousFirstName}  {this.state.PreviousLastName}</h4>
                                <h4>Email : {this.state.Email}</h4>
                                <div style={{ position: "absolute", right: 20, top: 32 }}>
                                    <Button style={{ background: "#4caf50" }} aria-label="edit" justIcon round onClick={() => {
                                        this.setState({ editform: true })
                                    }}>
                                        <CreateOutlinedIcon style={{ color: "#FFFFFF" }} />
                                    </Button>
                                </div>
                            </CardBody>}
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(Profile);