
import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { withRouter } from "react-router-dom";
import Settings from '../../Menu/GlobalConstant/Settings';
import LoaderComponent from '../../Menu/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Alert from '../../Menu/GlobalConstant/Alert'
import axios from 'axios';
import { HOST } from '../../Menu/config';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import QrReader from 'react-qr-reader'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    root: {
        // flexGrow: 1,
        // display: "grid",
        // height: "90vh"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const previewStyle = {
    height: 300,
    width: 300,
}
class Add extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            flagError: false,
            netWorkError: false,
            inputname: "",
            erroertext: "",
            vehicleid: "",
            vehicle: "",
            flatno: "",
            fullname: "",
            mobile: "",
            comments: "",
            tc: false,
            bc: false,
            modal: false,
            online: window.navigator.onLine,
            tagid: "",
            relayArreay: [
                { label: "1" },
                { label: "2" }
            ],
            relay: ""
        }
        this.r1Ref = React.createRef();
    }
    add = () => {
        let api = HOST + "/tag/addtag"
        let body = {
            vehicle: this.state.vehicle,
            flatno: this.state.flatno,
            fullname: this.state.fullname,
            mobile: this.state.mobile,
            tagid: this.state.tagid,
            // relay: this.state.relay,
            comments: this.state.comments
        }
      
            if (this.state.online) {


                this.setState({ loader: true })
                axios.post(api, body, {
                    headers: {
                        "authToken": this.token,
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json'
                    }
                }).then(res => {
                    if (res.status !== "") {
                        this.setState({ loader: false })
                        this.showNotification("tc")
                        setTimeout(() => { this.props.history.push('/tags') }, 2000);
                    }
                }).catch(err => {
                    if (err.response.data.statusid !== "") {
                        this.setState({ netWorkError: true, loader: false, erroertext: err.response.data.error })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                })
            }
            else {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
        

    }
    componentDidMount() {
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
    }

    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    handleScan = data => {
        if (data !== null) {

            var jsonStrig = '[{';
            var items = data.split('\n');
            for (var i = 0; i < items.length; i++) {
                var current = items[i].split(':');
                jsonStrig += '"' + current[0] + '":"' + current[1] + '",';
            }
            jsonStrig = jsonStrig.substr(0, jsonStrig.length - 1);
            jsonStrig += '}]';
            var obj = JSON.parse(jsonStrig)
            var finalObj = Object.values(obj[0])
            this.setState({
                fullname: finalObj[0],
                vehicle: finalObj[1],
                mobile: finalObj[2],
                modal: false
            })
            this.r1Ref.current.focus();
        }
    }
    handleError = err => {
        console.error(err)
    }
    handleChange = (event, values) => {
        if (values) {
            this.setState({
                relay: values.props.value
            });
        }
    }
    render() {

        const { classes } = this.props
        return (
            <div >
                <Dialog
                    // fullScreen={fullScreen}
                    open={this.state.modal}
                    // disableBackdropClick={true}
                    onClose={() => this.setState({ modal: false })}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" >
                        {"Scan QR to Share Activity"}
                        <IconButton aria-label="close" style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }} onClick={() => {
                            this.setState({ modal: false })
                        }}>
                            <CancelOutlinedIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ overflow: "hidden" }}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <QrReader
                                        delay={300}
                                        onError={this.handleError}
                                        onScan={this.handleScan}
                                        style={previewStyle}
                                        legacyMode={false}
                                        facingMode="environment"
                                    />
                                </GridItem>
                            </GridContainer>
                        </div>
                    </DialogContent>
                </Dialog>
                {this.state.tc ? <Snackbar
                    place="tc"
                    color="success"
                    icon={ThumbUpIcon}
                    message={" Tags " + this.state.erroertext}
                    open={this.state.tc}
                    closeNotification={() => this.setState({ tc: false })}
                    close
                /> : ""}
                <div className={classes.root}>


                    <GridContainer >
                        <GridItem xs={12} sm={12} md={2}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <Card >
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Add Tag")}</h4>
                                    <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please fill in all required feilds")}</p>
                                </CardHeader>
                                <CardBody>
                                    {this.state.netWorkError ? <SnackbarContent
                                        icon={InfoIcon}
                                        message={this.state.erroertext}
                                        // close
                                        color="danger"
                                    /> : ""}
                                    {this.state.loader ? <LoaderComponent /> : ""}
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="* Tag ID (24 characters)"
                                                id="tagid"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onInput: (e) => e.target.value = ("" + e.target.value).toUpperCase().toString().slice(0, 24),

                                                    onChange: (event) => {
                                                        this.setState({ tagid: event.target.value })
                                                        if (Settings.RFIDTagvalid(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.RFIDTagvalid(event.target.value).errmessgae, inputname: Settings.RFIDTagvalid(event.target.value).inputname })
                                                        }
                                                    },

                                                    type: "text",
                                                    //  value: this.state.tagid,
                                                    error: (this.state.flagError && this.state.inputname === "TagID") || (this.state.requirederror && this.state.tagid === "") ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.tagid ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "TagID" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="EMP ID(max. 10 characters)"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                id="FlatNo"
                                                inputRef={this.r1Ref}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ flatno: event.target.value })
                                                        if (Settings.FlatNo(event.target.value).status) {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.FlatNo(event.target.value).errmessgae, inputname: Settings.FlatNo(event.target.value).inputname })
                                                        }
                                                    },
                                                    type: "text",
                                                    value: this.state.flatno,
                                                    error: (this.state.flagError && this.state.inputname === "FlatNo") || (this.state.requirederror && this.state.flatno === "") ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.flatno ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "FlatNo" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Vehicle Number(max. 10 characters)"
                                                id="Vehicle Number"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ vehicle: event.target.value })
                                                        if (Settings.Vehicle(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.Vehicle(event.target.value).errmessgae, inputname: Settings.Vehicle(event.target.value).inputname })
                                                        }
                                                    },
                                                    onInput: (e) => e.target.value = ("" + e.target.value).toUpperCase().toString().slice(0, 10),
                                                    type: "text",
                                                    value: this.state.vehicle,
                                                    error: (this.state.flagError && this.state.inputname === "Vehicle") || (this.state.requirederror && this.state.vehicle === "") ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.vehicle ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "Vehicle" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>


                                        {/* <GridItem xs={12} sm={12} md={6}>
                                            <FormControl className={classes.formControl} style={{ width: "100%", marginTop: 25 }}>
                                                <InputLabel id="demo-simple-select-helper-label">* Relay</InputLabel>
                                                <Select
                                                    className={classes.root}
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    // value={this.state.serviceType}
                                                    onChange={this.handleChange}
                                                >
                                                    {this.state.relayArreay.map((entryCriteria, index) => (
                                                        <MenuItem value={entryCriteria.label}>{entryCriteria.label}</MenuItem>



                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {this.state.requirederror && !this.state.serviceType ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem> */}
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Mobile Number(10 digits)"
                                                id="Mobile"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ mobile: event.target.value })
                                                        if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: Settings.mobilenumber(event.target.value).inputname })
                                                        }
                                                    },
                                                    onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                    type: "number",
                                                    value: this.state.mobile,
                                                    error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.mobile ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Person Name(max. 20 characters)"
                                                id="Person Name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ fullname: event.target.value })
                                                        if (Settings.PersonName(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.PersonName(event.target.value).errmessgae, inputname: Settings.PersonName(event.target.value).inputname })
                                                        }
                                                    },

                                                    type: "text",
                                                    // value: this.state.fullname,
                                                    error: (this.state.flagError && this.state.inputname === "PersonName") || (this.state.requirederror && this.state.fullname === "") ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.fullname ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "PersonName" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>


                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Comments(max. 25)"
                                                id="Comments"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}

                                                inputProps={{
                                                    onInput: (e) => e.target.value = ("" + e.target.value).toString().slice(0, 25),

                                                    onChange: (event) => {
                                                        this.setState({ comments: event.target.value })
                                                        if (Settings.Comments(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.Comments(event.target.value).errmessgae, inputname: Settings.Comments(event.target.value).inputname })
                                                        }
                                                    },
                                                    type: "text",
                                                    error: this.state.flagError && this.state.inputname === "Comments" ? true : false
                                                }}
                                            />
                                            {this.state.flagError && this.state.inputname === "Comments" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={5}>
                                            {this.state.tagid !== "" && Settings.RFIDTagvalid(this.state.tagid).inputname ==="" ?
                                                <Button color="primary" onClick={this.add}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                                <Button onClick={() => {
                                                    if (Settings.requireerrors([this.state.vehicle, this.state.flatno, this.state.mobile, this.state.fullname, this.state.tagid]).status === true) {
                                                        this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.companyname, this.state.mobile, this.state.companytype, this.state.tagid]).errmessgae })
                                                        setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                    }
                                                }}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                            }
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={1}>
                                            <Button color="white" onClick={() => {
                                                this.props.history.push('/tags')
                                            }}><CancelPresentationIcon />Cancel</Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                        </GridItem>
                                    </GridContainer>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}
export default withRouter((withStyles(styles)(Add)));

