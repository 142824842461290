/*eslint-disable*/
import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import Settings from "../../Menu/GlobalConstant/Settings";
import { HOST } from "Menu/config";
import axios from "axios";
const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const roletype = window.sessionStorage.getItem("role");
  const oem = window.sessionStorage.getItem("oem");
  const support = window.sessionStorage.getItem("support");
  const visitor = window.sessionStorage.getItem("visitor");
  const tenant = window.sessionStorage.getItem("tenant");

  const logopath = window.sessionStorage.getItem("logopath");
  const token = localStorage.getItem("token");

  const classes = useStyles();
  const [SidemenuData, setSidemenuData] = React.useState([]);
  // verifies if routeName is the one active (in browser input)
  useEffect(() => {
    if (window.location.pathname !== "/tags/findtags") {
      let api = HOST + "/rfid/updaterfidtagmap";
      let body = {
        rfidtagmap: false,
      };

      // this.setState({ showtable: true })
      axios
        .put(api, body, {
          headers: {
            authToken: token,
            Accept: "application/json",
            // 'Content-Type': 'application/json'
          },
        })
        .then((res) => {
          });
    }
  });
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes &&
        routes.map((prop, key) => {
          var activePro = " ";
          var listItemClasses;
          if (prop.path === "/upgrade-to-pro") {
            activePro = classes.activePro + " ";
            listItemClasses = classNames({
              [" " + classes[color]]: true,
            });
          } else {
            listItemClasses = classNames({
              [" " + classes[color]]: activeRoute(prop.layout + prop.path),
            });
          }
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
          });
          if (oem === "true" && support === "true" && visitor === "true") {
            if (
              roletype === "IsManager" &&
              tenant === "false" &&
              key !== 3 &&
              key !== 5
            ) {
              return (
                <NavLink
                  to={prop.layout + prop.path}
                  className={activePro + classes.item}
                  activeClassName="active"
                  key={key}
                >
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}
                    <ListItemText
                      primary={
                        props.rtlActive
                          ? prop.rtlName
                          : Settings.titleCase(prop.name)
                      }
                      className={classNames(
                        classes.itemText,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
              );
            } else if (roletype === "IsManager" && key !== 3) {
              if (tenant === "true") {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
            } else if (
              roletype === "IsTenant" &&
              key !== 0 &&
              key !== 2 &&
              key !== 3 &&
              key !== 4 &&
              key !== 5 &&
              key !== 6
            ) {
              return (
                <NavLink
                  to={prop.layout + prop.path}
                  className={activePro + classes.item}
                  activeClassName="active"
                  key={key}
                >
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}
                    <ListItemText
                      primary={
                        props.rtlActive
                          ? prop.rtlName
                          : Settings.titleCase(prop.name)
                      }
                      className={classNames(
                        classes.itemText,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
              );
            } else {
              if (
                key !== 0 &&
                key !== 1 &&
                key !== 2 &&
                key !== 3 &&
                key !== 4 &&
                key !== 5 &&
                key !== 6
              ) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
            }
          } else if (oem === "false" && support === "true") {
            if (visitor === "true" && tenant === "true") {
              if (roletype === "IsManager" && key !== 3) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              } else if (
                roletype === "IsTenant" &&
                key !== 0 &&
                key !== 2 &&
                key !== 3 &&
                key !== 4 &&
                key !== 5 &&
                key !== 6
              ) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
            } else if (visitor === "true") {
              if (roletype === "IsManager" && key !== 3 && key !== 5) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              } else if (
                roletype === "IsTenant" &&
                key !== 0 &&
                key !== 2 &&
                key !== 3 &&
                key !== 4 &&
                key !== 5 &&
                key !== 6
              ) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
            } else if (tenant === "true") {
              if (roletype === "IsManager" && key !== 3 && key !== 4) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              } else if (
                roletype === "IsTenant" &&
                key !== 0 &&
                key !== 2 &&
                key !== 3 &&
                key !== 4 &&
                key !== 5 &&
                key !== 6
              ) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
            } else {
              if (key !== 4 && key !== 3 && key !== 5) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
            }
          } else {
            if (
              roletype === "IsManager" &&
              key !== 3 &&
              key !== 4 &&
              key !== 5
            ) {
              return (
                <NavLink
                  to={prop.layout + prop.path}
                  className={activePro + classes.item}
                  activeClassName="active"
                  key={key}
                >
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}
                    <ListItemText
                      primary={
                        props.rtlActive
                          ? prop.rtlName
                          : Settings.titleCase(prop.name)
                      }
                      className={classNames(
                        classes.itemText,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
              );
            } else if (
              roletype === "IsTenant" &&
              key !== 0 &&
              key !== 2 &&
              key !== 3 &&
              key !== 4 &&
              key !== 5 &&
              key !== 6
            ) {
              return (
                <NavLink
                  to={prop.layout + prop.path}
                  className={activePro + classes.item}
                  activeClassName="active"
                  key={key}
                >
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}
                    <ListItemText
                      primary={
                        props.rtlActive
                          ? prop.rtlName
                          : Settings.titleCase(prop.name)
                      }
                      className={classNames(
                        classes.itemText,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
              );
            } else {
              if (
                key !== 1 &&
                key !== 3 &&
                key !== 4 &&
                key !== 5 &&
                key !== 6
              ) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={
                          props.rtlActive
                            ? prop.rtlName
                            : Settings.titleCase(prop.name)
                        }
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
            }
          }
        })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://gaido.in"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
        {oem === "false" ? (
          <div className={classes.logoImage}>
            <img
              src={require("../../img/Gaido_logo_Signature.png")}
              alt="logo"
              className={classes.img}
            />
          </div>
        ) : (
          <div className={classes.logoImage}>
            <img
              src={logopath}
              alt="logo"
              style={{ height: 60, width: 145, paddingLeft: 40 }}
            />
          </div>
        )}
        {/* {logoText} */}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
