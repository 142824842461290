import React from 'react';
import { Switch, Route, } from 'react-router-dom';
import Login from './login';
import ForgotPassword from './forgot-password';
import Dashboard from './home/dashboard';
import PrivateRoute from './PrivateRoute';
import Search from './Searchplates/Search'
import Database from './home/Databse/Database';
import AddDatabase from './home/Databse/Add';
import BulkUpload from './home/Databse/BulkUpload'
import EditDatabase from './home/Databse/Edit';
import ViewDatabase from './home/Databse/View';
import ChangepwdSettings from './users/ChangepwdSettings';
import Resetpassword from './changepassword';
import Reports from './reports/Reports';
import Profile from './users/Profile';
import Unauthrized from './Unauthrized';
import HelpSupports from "./HelpSupports/HelpSupports";
import SuccessSms from './SuccessSms'
import Support from './HelpSupports/HelpSupportTable'
import FindTags from './home/Databse/FindTags';




// import SuccessRequest from './Successrequest'
class App extends React.Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem('token');
    this.roletype = localStorage.getItem('role');
    this.user = localStorage.getItem('username');
    this.state = {
      auth: false
    }
    this.requireAuth = this.requireAuth.bind(this);
  }
  componentDidMount() {
    window.addEventListener("popstate", this.onBackButtonEvent)
  }
  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackButtonEvent)
  }
  onBackButtonEvent = () => {
    if (window.location.pathname === "/") {
      localStorage.setItem('token', null)
      window.sessionStorage.removeItem('token');
      window.sessionStorage.removeItem('role');
      window.location = "/dashboard"
    }
  }
  requireAuth(nextState, replace) {

    if ("null" === window.sessionStorage.getItem('token')) {
      replace({
        pathname: '/'
      })
    }
  }

  render() {
    let routes;
    routes = (
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/auth" component={Unauthrized} />
        <Route path="/SuccessSms" component={SuccessSms} />
        <Route path="/reset" component={Resetpassword} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/Dashboard" role={[{ role1: "All", path: "Dashboard" }]} component={Dashboard} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", role2: "IsTenant", path: "tags" }]} path="/tags" component={Database} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", role2: "IsTenant", path: "tags" }]} path="/tags/add" component={AddDatabase} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", role2: "IsTenant", path: "tags" }]} path="/tags/bulk" component={BulkUpload} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", role2: "IsTenant", path: "tags" }]} path="/tags/edit" component={EditDatabase} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", role2: "IsTenant", path: "tags" }]} path="/tags/view" component={ViewDatabase} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", role2: "IsTenant", path: "tags" }]} path="/tags/findtags" component={FindTags} onEnter={this.requireAuth} />

        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/search" role={[{ role1: "All", path: "search" }]} component={Search} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/reports" role={[{ role1: "All", path: "reports" }]} component={Reports} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/help" role={[{ role1: "All", path: "help" }]} component={HelpSupports} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/profile" role={[{ role1: "All", path: "profile" }]} component={Profile} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/support" role={[{ role1: "All", path: "support" }]} component={Support} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/settings" role={[{ role1: "All", path: "settings" }]} component={ChangepwdSettings} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/findtags" role={[{ role1: "All", path: "settings" }]} component={FindTags} onEnter={this.requireAuth} />

        
        {/* 
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", role2: "IsTenant",path: "vehicle" }]} path="/vehicle" component={Database} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "visitor" }]} path="/visitor" component={Visitor} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "visitor" }]} path="/visitor/add" component={AddVisitor} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager",  path: "visitor" }]} path="/visitor/edit" component={EditVisitor} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "visitor" }]} path="/visitor/view" component={ViewVisitor} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "visitor" }]} path="/visitor/history" component={visitorReport} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "visitor" }]} path="/visitor/registervisitor" component={RegisterVisitor} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "tenant" }]} path="/tenant" component={TenantDashboard} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "tenant" }]} path="/tenant/add" component={AddTenant} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "tenant" }]} path="/tenant/view" component={Tenantview} onEnter={this.requireAuth} exact />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} role={[{ role1: "IsManager", path: "tenant" }]} path="/tenant/edit" component={TenantEdit} onEnter={this.requireAuth} exact />



        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/support" role={[{ role1: "All", path: "support" }]} component={Support} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/search"  role={[{ role1: "All", path: "search" }]} component={Search} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/reports" role={[{ role1: "All", path: "reports" }]} component={Reports} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/help"    role={[{ role1: "All", path: "help" }]} component={HelpSupports} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/profile" role={[{ role1: "All", path: "profile" }]} component={Profile} onEnter={this.requireAuth} />
        <PrivateRoute isAuth={(window.sessionStorage.getItem("role") !== "") ? true : false} path="/settings"role={[{ role1: "All", path: "settings" }]} component={ChangepwdSettings} onEnter={this.requireAuth} /> */}
      </Switch>
    )
    return (
      <div className="App" key={this.token}>
        {routes}
      </div>
    );
  }
}



export default App;


