import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Settings from './GlobalConstant/Settings'
import LoaderComponent from './GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Alert from './GlobalConstant/Alert'
import axios from 'axios';
import Snackbar from "../components/Snackbar/Snackbar.js";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import InfoIcon from '@material-ui/icons/Info';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { AUTH } from './config'

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    dialog: {
        // backgroundImage: "url(http://lpr.gaido.in/static/media/bulkcar.8585b4d8.jpg)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: "100%",
        overflowX: "hidden",
        overflowy: "auto",

    },
    root: {
        width: "100%",
        minHeight: 330,
        position: "relative",
        top: "50%",
        left: "50%",
        // webkitTransform:" translateY(-50%) translateX(-50%)",
        transform: "translateY(-50%) translateX(-50%)",
    }
};
class ForgotPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            mobile: '',
            email: "",
            requirederror: false,
            mobilelength: false,
            selectedmobile: true,
            selectedemail: false,
            loader: false,
            flagError: false,
            erroertext: "",
            inputname: "",
            tc: false,
            bc: false,
            netWorkError: false,
            online: window.navigator.onLine

        }

    }

    verify = () => {

        if (Settings.mobilenumber(this.state.mobile).status) {
            this.setState({ loader: true })
            let api = AUTH + "/forgotpwd"
            let body = { username: this.state.mobile }
            if(this.state.online){
                axios.post(api, body, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    this.setState({ loader: false, mobile: "" })
                    this.props.history.push("/SuccessSms");
                }).catch(err => {
                    if (err.message === "Network Error") {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                    if (err.response) {
                        if (err.response.status === 502) {
                            this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                            setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                        }
                    }
                    if (err.response.status === 409) {
                        this.setState({ loader: false });
                        this.setState({ flagError: true, erroertext: "Mobile not registred with us." , inputname: "Mobile"  });
                        setTimeout(() => { this.setState({ flagError: false }) }, 2000)
                    }
                })
            }else{
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
        } else {
            this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.mobile).errmessgae, inputname: Settings.mobilenumber(this.state.mobile).inputname })
            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        }
    }

    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    componentDidMount() {
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
      }
      
      componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
      }
      handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
      }
    render() {
        const { classes } = this.props
        return (
            <div className={classes.dialog}>
                <div className={classes.root}>
                    <GridContainer  >
                        {this.state.tc ? <Snackbar
                            place="tc"
                            color="success"
                            icon={ThumbUpIcon}
                            message={" Company " + this.state.erroertext}
                            open={this.state.tc}
                            closeNotification={() => this.setState({ tc: false })}
                            close
                        /> : ""}
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} className={classes.root}>
                            <Card >
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Forgot password")}</h4>
                                    <p className={classes.cardCategoryWhite}>{ Settings.capitalizeFLetter("Please enter your registered mobile or email to receive a link for change password.")}</p>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer >
                                        <GridItem xs={12} sm={12} md={12} direction="row">
                                            {/* <FormControlLabel value="Mobile" control={<GreenRadio />} label="Mobile" /> */}
                                            <CustomInput
                                                labelText="Mobile Number(10 digits)"
                                                id="Mobile"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {this.setState({ mobile: event.target.value })
                                                    if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: Settings.mobilenumber(event.target.value).inputname })
                                                    }
                                                },
                                                    onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                    type: "number",
                                                    value: this.state.mobile,
                                                    error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.mobile ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {/* <FormControlLabel value="Email" control={<GreenRadio />} label="Email" /> */}
                                            {/* <CustomInput
                                                labelText="Email"
                                                id="Email"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                               

                                            /> */}
                                        </GridItem>
                                    </GridContainer>

                                </CardBody>
                                <CardFooter>
                                    {/* <Button color="primary">Verify</Button> */}
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={5}>
                                            {this.state.mobile !== "" ?
                                                <Button color="primary" onClick={this.verify}><VerifiedUserIcon /> Verify</Button> :
                                                <Button onClick={() => {
                                                    if (Settings.requireerrors([this.state.mobile]).status === true) {
                                                        this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.mobile]).errmessgae })
                                                        setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                    }
                                                }}> <VerifiedUserIcon /> Verify</Button>
                                            }
                                        </GridItem>
                                        {/* <GridItem xs={12} sm={12} md={1}>
                                            <Button color="primary" onClick={() => {
                                                this.props.history.push('/')
                                            }}> <LockIcon />Sign In</Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                        </GridItem> */}
                                    </GridContainer>
                                    <p color="primary" style={{ marginRight: 5, cursor: "pointer",color:"#1877f2" }} onClick={() => {
                                        this.props.history.push('/')
                                    }}>{Settings.titleCase("sign in")}</p>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ForgotPassword);
