import React, { Component } from 'react';
import axios from 'axios'
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from '@material-ui/core/colors';
import { AUTH } from './config';
import LoaderComponent from './GlobalConstant/LoaderComponent'
import Settings from './GlobalConstant/Settings';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
// const GreenCheckbox = withStyles({
//   root: {
//     color: green[400],
//     '&$checked': {
//       color: green[600],
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  dialog: {
    // backgroundImage: "url(http://lpr.gaido.in/static/media/bulkcar.8585b4d8.jpg)",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: "100%",
    overflowX: "hidden",
    overflowy: "auto",
  },
  root: {
    width: "100%",
    minHeight: 330,
    position: "relative",
    top: "50%",
    left: "50%",
    // webkitTransform:" translateY(-50%) translateX(-50%)",
    transform: "translateY(-50%) translateX(-50%)",
  }
};
class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: "",
      Password: "",
      netWorkError: false,
      flagError2: false,
      flagError3: false,
      loader: false,
      erroertext: "",
      roletype: "",
      i_agree: false,
      viewpassword: false,
      inputname: "",
      online: window.navigator.onLine,
      checkedG: false,
      newpassword: '',
      confirmpassword: '',
      vehicles: [],
      flagError: false,
      flagError1: false,
      showPassword: false,
      showPassword1: false,
      requirederror: false,
      minmaxerror: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.addEventListener('offline', this.handleNetworkChange);
    window.addEventListener('online', this.handleNetworkChange);

    // this.location = window.location.href.replace('http://localhost:3000/reset?', '');
    this.location = window.location.href.replace('https://access.gaido.in/reset?', '');
  }
  handleSubmit(e) {
    this.setState({ flagError: false });
    const obj = {
      resetcode: this.location,
      pwd: this.state.newpassword
    };
    const { newpassword, confirmpassword } = this.state;
    if (newpassword !== confirmpassword) {
      this.setState({ flagError3: true, erroertext: "New password and confirm passowrd didn't match" });
      setTimeout(() => { this.setState({ flagError3: false }) }, 3000);
    } else {
      if (Settings.NewPasswordMain(this.state.newpassword).status) {
        if (Settings.ConfirmPasswordMain(this.state.confirmpassword).status) {
          this.setState({ loader: true });
          axios.put(AUTH + '/resetpassword', obj, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }).then(res => {
              if (res.data.statusid === 202) {
                this.setState({ loader: false })
                this.setState({ flagError2: true, erroertext: "Something went wrong please try again later" });
                setTimeout(() => {
                  this.setState({ flagError2: false })
                  this.props.history.push("/")
                }, 2000);
              } else {
                this.setState({ loader: false })
                this.setState({ flagError1: true, erroertext: "Password Successfully updated" });
                setTimeout(() => {
                  this.setState({ flagError1: false })
                  this.props.history.push("/")
                }, 5000);
              }
            })
            .catch(err => {
              if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
              }
              if (err.response) {
                if (err.response.status === 401) {
                  this.setState({ netWorkError: true, loader: false, erroertext: Settings.Unauthrized().errmessgae })
                  setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response.status === 502) {
                  this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                  setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
              }
            })
        } else {
          this.setState({ flagError: true, erroertext: Settings.ConfirmPasswordMain(this.state.newpassword).errmessgae, inputname: Settings.ConfirmPasswordMain(this.state.newpassword).inputname })
        }

      } else {
        this.setState({ flagError: true, erroertext: Settings.ConfirmPasswordMain(this.state.newpassword).errmessgae, inputname: Settings.ConfirmPasswordMain(this.state.newpassword).inputname })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.handleNetworkChange);
    window.removeEventListener('online', this.handleNetworkChange);
  }
  handleNetworkChange = () => {
    this.setState({ online: window.navigator.onLine });
  }


  render() {

    const { classes } = this.props
    return (
      <div className={classes.dialog}>
        <div className={classes.root}>
          <GridContainer  >
            <GridItem xs={12} sm={12} md={4}>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} className={classes.root}>
              <Card >
                <CardHeader color="primary" >
                  <h4 className={classes.cardTitleWhite} >{Settings.titleCase("Reset Password")}</h4>
                  <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please fill in all required feilds")}</p>
                </CardHeader>
                <CardBody>
                  <GridContainer  >
                    <GridItem xs={12} sm={12} md={12} >
                      {this.state.netWorkError ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError2 ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError3 ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError1 ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="success"
                      /> : ""}
                      <CustomInput
                        labelText="* New Password(min. 3 & max. 20)"
                        id="Mobile"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ newpassword: event.target.value })
                            if (Settings.NewPasswordMain(event.target.value).status) {
                              this.setState({ flagError: false, erroertext: "" })
                            } else {
                              this.setState({ flagError: true, erroertext: Settings.NewPasswordMain(event.target.value).errmessgae, inputname: Settings.NewPasswordMain(event.target.value).inputname })
                            }
                          },
                          type: this.state.showPassword ? "text" : "password",
                          value: this.state.newpassword,
                          error: this.state.newpassword === "" ? this.state.requirederror : "",
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({ showPassword: !this.state.showPassword })
                              }}>
                                {this.state.showPassword ? <VisibilityIcon className={classes.inputIconsColor} /> : <VisibilityOffIcon className={classes.inputIconsColor} />}
                              </div>
                            </InputAdornment>
                          )
                        }}
                      />
                      {this.state.requirederror && !this.state.newpassword ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError && this.state.inputname === "NewPassword" ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="* Confirm Password(min. 3 & max. 20)"
                        id="Mobile"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ confirmpassword: event.target.value })
                            if (Settings.ConfirmPasswordMain(event.target.value).status) {
                              this.setState({ flagError: false, erroertext: "" })
                            } else {
                              this.setState({ flagError: true, erroertext: Settings.ConfirmPasswordMain(event.target.value).errmessgae, inputname: Settings.ConfirmPasswordMain(event.target.value).inputname })
                            }
                          },
                          type: this.state.showPassword1 ? "text" : "password",
                          value: this.state.confirmpassword,
                          error: this.state.confirmpassword === "" ? this.state.requirederror : "",
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({ showPassword1: !this.state.showPassword1 })
                              }}>
                                {this.state.showPassword1 ? <VisibilityIcon className={classes.inputIconsColor} /> : <VisibilityOffIcon className={classes.inputIconsColor} />}
                              </div>
                            </InputAdornment>
                          )
                        }}
                      />
                      {this.state.requirederror && !this.state.confirmpassword ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError && this.state.inputname === "ConfirmPassword" ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <div style={{ cursor: "pointer" }}>
                    {this.state.newpassword !== "" && this.state.confirmpassword !== "" ? <Button color={"primary"} onClick={this.handleSubmit}>Reset</Button> : <Button
                      onClick={() => {
                        if (Settings.requireerrors([this.state.newpassword, this.state.confirmpassword]).status === true) {
                          this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.newpassword, this.state.confirmpassword]).errmessgae })
                          setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                        }
                      }}
                    >Reset</Button>}
                  </div>
                  <p color="primary" style={{ marginRight: 5, cursor: "pointer", color: "#1877f2" }} onClick={() => {
                    this.props.history.push('/')
                  }}>{Settings.titleCase("Sign In")}</p>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            </GridItem>
          </GridContainer>
          {this.state.loader ? <LoaderComponent /> : ""}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(login);
